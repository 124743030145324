<template>
    <div class="platform-filter">
        <md-field class="platform-filter-field">
            <img
                v-if="isCustomIcon(localPlatform)"
                class="custom-icon filter-icon"
                :src="platform_icon(localPlatform)"
                :alt="localPlatform"
            />
            <svg-icon v-else-if="localPlatform === 'ALL'" class="filter-icon" :name="platform_icon(localPlatform)" />
            <i v-else class="filter-icon" :class="platform_icon(localPlatform)"></i>
            <md-select v-model="localPlatform" name="platform">
                <md-option value="ALL">All Platforms</md-option>
                <md-option value="WHATSAPP">WhatsApp</md-option>
                <md-option value="FACEBOOK">Facebook</md-option>
                <md-option value="INSTAGRAM">Instagram</md-option>
            </md-select>
        </md-field>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'PlatformFilter',
    components: { SvgIcon },
    props: {
        selectedPlatform: {
            type: String,
            default: 'ALL',
        },
    },
    data() {
        return {
            localPlatform: this.selectedPlatform,
        };
    },
    watch: {
        localPlatform(newValue) {
            this.$emit('platform-filter-changed', newValue);
        },
        selectedPlatform(newValue) {
            this.localPlatform = newValue;
        },
    },
    methods: {
        isCustomIcon(platform) {
            return ['WHATSAPP', 'FACEBOOK'].includes(platform);
        },
        platform_icon(platform) {
            switch (platform) {
                case 'WHATSAPP':
                    return 'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FLogo-whatsapp.png?alt=media&token=94a44ade-fdad-4f57-87ad-ad4dd45a1daf';
                case 'FACEBOOK':
                    return 'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffacebook_messenger_icon.png?alt=media&token=b6442f05-380c-4fa1-b56c-d8bb7c70bd79';
                case 'INSTAGRAM':
                    return 'fab fa-instagram-square';
                default:
                    return 'FILTER_ALL';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-field {
    margin-top: 0;
}

.platform-filter-field {
    display: flex;
    align-items: center;
}

.filter-icon {
    height: 15px;
    width: 15px;
    margin-right: 8px;
    fill: $mdb-text-color-primary;
}

.custom-icon {
    object-fit: contain;
}
</style>

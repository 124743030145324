import { render, staticRenderFns } from "./TagChip.vue?vue&type=template&id=29e32b31&scoped=true"
import script from "./TagChip.vue?vue&type=script&lang=js"
export * from "./TagChip.vue?vue&type=script&lang=js"
import style0 from "./TagChip.vue?vue&type=style&index=0&id=29e32b31&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e32b31",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29e32b31')) {
      api.createRecord('29e32b31', component.options)
    } else {
      api.reload('29e32b31', component.options)
    }
    module.hot.accept("./TagChip.vue?vue&type=template&id=29e32b31&scoped=true", function () {
      api.rerender('29e32b31', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/RoomsList/TagChip/TagChip.vue"
export default component.exports
<template>
    <div>
        <md-button @click="launchMessengerSignup" class="md-primary" :disabled="isLoading">
            {{ isLoading ? 'Connecting...' : 'Connect' }}
        </md-button>
        <div id="status"></div>
    </div>
</template>

<script>
import facebookMesengerService from '@/services/meta/facebookMessengerService';

export default {
    name: 'ChannelsFacebookLoginButton',

    emits: ['account-connected'],

    data() {
        return {
            isLoading: false,
            pageId: null,
            pageName: null,
            code: null,
            accessToken: null,
        };
    },

    methods: {
        loadFacebookSDK() {
            return new Promise((resolve, reject) => {
                if (document.getElementById('facebook-jssdk')) {
                    resolve();
                    return;
                }

                window.fbAsyncInit = () => {
                    FB.init({
                        appId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
                        cookie: true,
                        xfbml: true,
                        version: 'v19.0',
                    });
                    resolve();
                };

                const script = document.createElement('script');
                script.id = 'facebook-jssdk';
                script.src = 'https://connect.facebook.net/en_US/sdk.js';
                script.async = true;
                script.defer = true;
                script.crossOrigin = 'anonymous';
                script.onerror = reject;
                document.body.appendChild(script);
            });
        },

        async launchMessengerSignup() {
            this.isLoading = true;
            try {
                await this.loadFacebookSDK();
                const response = await this.fbLogin();
                console.log({ response });

                if (response.status === 'connected' && response.authResponse.code) {
                    this.code = response.authResponse.code;

                    await facebookMesengerService.setupFacebookMessenger(this.code);
                } else {
                    throw new Error('User cancelled login or did not fully authorize.');
                }
            } catch (error) {
                this.showErrorToast(error.message);
            } finally {
                this.isLoading = false;
            }
        },

        fbLogin() {
            return new Promise((resolve) => {
                FB.login(resolve, {
                    config_id: process.env.VUE_APP_FACEBOOK_MESSENGER_CONFIGURATION_ID,
                    response_type: 'code',
                    override_default_response_type: true,
                    scope: 'public_profile,pages_messaging,pages_manage_metadata,pages_show_list,pages_read_engagement',
                });
            });
        },

        updateStatus(message) {
            document.getElementById('status').innerHTML = message;
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>

<template>
    <div
        v-show="showCustomerDetails && Object.keys(room).length > 0"
        class="vac-customer-container"
        :class="{
            'vac-customer-container-full': isMobile,
        }"
    >
        <div class="customer-container">
            <div class="vac-mobile-arrow-back" v-if="!loadingRooms && isMobile">
                <div @click="$emit('close-mobile-view')" class="custom-arrow-back-button">
                    <svg-icon name="arrowBackAlt" />
                    <span class="md-body-2">Chat</span>
                </div>
            </div>

            <div name="rooms-list-search">
                <customer-card-header
                    v-if="!showDetailsOrder"
                    ref="showCustomerHeader"
                    :current-user-id="currentUserId"
                    :current-user-name="currentUserName"
                    :current-user-email="currentUserEmail"
                    :current-user-phone="currentUserPhone"
                    :current-customer-platforms-info="currentCustomerPlatformsInfo"
                    :current-user-satisfaction="currentUserSatisfaction"
                    :show-add-room="showAddRoom"
                    :show-customer-header="showCustomerHeader"
                    :room="room"
                    @add-room="$emit('add-room')"
                >
                </customer-card-header>
            </div>

            <md-divider v-if="!showDetailsOrder" class="section-divider"></md-divider>

            <CustomerNotes v-if="!showDetailsOrder" :room="room"></CustomerNotes>

            <md-divider v-if="!showDetailsOrder" class="section-divider"></md-divider>

                <customer-card-body
                    :current-user-id="currentUserId"
                    :current-user-name="currentUserName"
                    :current-user-email="currentUserEmail"
                    :current-user-phone="currentUserPhone"
                    :current-user-satisfaction="currentUserSatisfaction"
                    :orders="orders"
                    :loadingOrders="loadingOrders"
                    :notes="notes"
                    @get-notes-order="getNotesOrder"
                    @toggle-details-order="toggleShowDetailsOrder"
                ></customer-card-body>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/components/Chat/Loader/Loader.vue';

import CustomerCardHeader from '../CustomerDetails/CustomerCardHeader/CustomerCardHeader.vue';
import CustomerCardBody from './CustomerCardBody/CustomerCardBody.vue';
import CustomerNotes from './CustomerCardBody/CustomerNotes/CustomerNotes.vue';

import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'CustomerDetails',
    components: {
        Loader,
        CustomerCardHeader,
        CustomerCardBody,
        CustomerNotes,
        SvgIcon,
    },

    props: {
        currentUserId: { type: [String, Number], required: true },
        showCustomerDetails: { type: Boolean, required: true },
        showCustomerHeader: { type: Boolean, required: true },
        showAddRoom: { type: Boolean, required: true },
        isMobile: { type: Boolean, required: true },
        loadingRooms: { type: Boolean, required: true },
        customSearchRoomEnabled: { type: [Boolean, String], default: false },
        currentUserName: { type: String, required: true },
        currentCustomerPlatformsInfo: { type: Object, required: true },
        currentUserPhone: { type: String, required: false, default: undefined },
        currentUserSatisfaction: { type: String, required: true },
        room: { type: Object, required: true },
        orders: { type: Object, required: true },
        loadingOrders: { type: Boolean, required: true },
        notes: { type: String },
        showDetailsOrder: { type: Boolean, required: true },
    },

    emits: ['add-room', 'get-notes-order', 'close-mobile-view', 'toggle-details-order'],

    methods: {
        getNotesOrder(order) {
            this.$emit('get-notes-order', order);
        },
        toggleShowDetailsOrder() {
            this.showDetailsOrder = !this.showDetailsOrder;
            this.$emit('toggle-details-order');
        },
    },
};
</script>

<style scoped>
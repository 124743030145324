<template>
    <div>
        <md-button @click="launchInstagramSignup" class="md-primary" :disabled="isLoading">
            {{ isLoading ? 'Connecting...' : 'Connect' }}
        </md-button>
        <div id="status"></div>
    </div>
</template>

<script>
import communicationChannelsService from '@/services/account/communicationChannelsService';
import metaService from '@/services/meta/metaService';

export default {
    name: 'ChannelsInstagramLoginButton',

    emits: ['account-connected'],

    data() {
        return {
            isLoading: false,
            accountId: null,
            pageName: null,
            code: null,
            accessToken: null,
        };
    },

    methods: {
        loadFacebookSDK() {
            return new Promise((resolve, reject) => {
                if (document.getElementById('facebook-jssdk')) {
                    resolve();
                    return;
                }

                window.fbAsyncInit = () => {
                    FB.init({
                        appId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
                        cookie: true,
                        xfbml: true,
                        version: 'v19.0',
                    });
                    resolve();
                };

                const script = document.createElement('script');
                script.id = 'facebook-jssdk';
                script.src = 'https://connect.facebook.net/en_US/sdk.js';
                script.async = true;
                script.defer = true;
                script.crossOrigin = 'anonymous';
                script.onerror = reject;
                document.body.appendChild(script);
            });
        },

        async launchInstagramSignup() {
            this.isLoading = true;
            try {
                await this.loadFacebookSDK();
                const response = await this.fbLogin();
                console.log({ response });

                if (response.status === 'connected' && response.authResponse.code) {
                    this.code = response.authResponse.code;
                    await this.handleCodeChange(this.code);
                    await this.getInstagramId();
                    await this.handleSuccessfulLogin();
                } else {
                    throw new Error('User cancelled login or did not fully authorize.');
                }
            } catch (error) {
                this.showErrorToast(error.message);
            } finally {
                this.isLoading = false;
            }
        },

        fbLogin() {
            const clientId = process.env.VUE_APP_INSTAGRAM_CONFIGURATION_ID;
            const redirectUri = encodeURIComponent(
                'https://api-dev.lixsa.ai/accounts/communication-channels/meta/access-token/instagram/',
            );
            const responseType = 'code';
            const scope = encodeURIComponent(
                'business_basic,business_manage_messages,business_manage_comments,business_content_publish',
            );

            const oauthUrl = `https://www.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;

            window.location.href = oauthUrl;
        },

        async handleCodeChange(code) {
            try {
                console.log('Trying to obtain Facebook token...', { code });
                const accessToken = await this.obtainInstagramToken(code);
                console.log({ accessToken });

                this.accessToken = accessToken;
            } catch (e) {
                console.error('Error creating communication channel: ', e);
            }
        },

        async obtainInstagramToken(code) {
            try {
                const response = await metaService.getInstagramAccessCode(code);
                return response.data;
            } catch (e) {
                console.error('Error obtainFacebookToken: ', e);
            }
        },

        async getInstagramId() {
            try {
                const response = await metaService.getInstagramData(this.accessToken);

                console.log({ response });

                const { access_token, account_id, name } = response.data;

                this.accountId = account_id;
                this.accessToken = access_token;
                this.pageName = name;

                this.updateStatus(`Connected to page: ${name}`);
            } catch (error) {
                throw new Error(`Error getting page ID: ${error.message}`);
            }
        },

        async handleSuccessfulLogin() {
            try {
                await this.subscribePageToWebhooks();
                await this.createMessengerChannel();
                this.updateStatus('Connected successfully!');
                this.$emit('account-connected');
            } catch (error) {
                this.showErrorToast(`Error during login process: ${error.message}`);
            }
        },

        async subscribePageToWebhooks() {
            try {
                await metaService.subscribeInstagramToWebhooks(this.accessToken, this.accountId);
            } catch (error) {
                throw new Error(`Error subscribing page to webhooks: ${error.message}`);
            }
        },

        async createMessengerChannel() {
            try {
                const reqData = {
                    access_token: this.accessToken,
                    account_id: this.accountId,
                    name: this.pageName,
                    channel_type: 'MESSENGER',
                };
                await communicationChannelsService.createCommunicationChannel(reqData);
                this.showSuccessToast('Messenger channel created successfully.');
            } catch (error) {
                throw new Error(`Error creating Messenger channel: ${error.message}`);
            }
        },

        updateStatus(message) {
            document.getElementById('status').innerHTML = message;
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>

import { render, staticRenderFns } from "./TagsFilter.vue?vue&type=template&id=3b9e8373&scoped=true"
import script from "./TagsFilter.vue?vue&type=script&lang=js"
export * from "./TagsFilter.vue?vue&type=script&lang=js"
import style0 from "./TagsFilter.vue?vue&type=style&index=0&id=3b9e8373&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b9e8373",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b9e8373')) {
      api.createRecord('3b9e8373', component.options)
    } else {
      api.reload('3b9e8373', component.options)
    }
    module.hot.accept("./TagsFilter.vue?vue&type=template&id=3b9e8373&scoped=true", function () {
      api.rerender('3b9e8373', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/RoomsList/FiltersSideBar/TagsFilter.vue"
export default component.exports
import BaseDto from '../../_common/BaseDto';

class CustomerResponseDataBuilder {
    constructor() {
        this.uuid = undefined;
        this.externalId = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.platformsInfo = undefined;
        this.email = undefined;
        this.phone = undefined;
        this.account = undefined;
        this.note = undefined;
        this.marketingConsent = undefined;
        this.marketingConsentUpdated = undefined;
        this.dateCreated = undefined;
        this.dateUpdated = undefined;
        this.customerFullName = undefined;
        this.anonimous = undefined;
    }

    withUuid(uuid) {
        this.uuid = uuid;
        return this;
    }

    withExternalId(externalId) {
        this.externalId = externalId;
        return this;
    }

    withFirstName(firstName) {
        this.firstName = firstName;
        return this;
    }

    withLastName(lastName) {
        this.lastName = lastName;
        return this;
    }

    withEmail(email) {
        this.email = email;
        return this;
    }

    withPhone(phone) {
        this.phone = phone;
        return this;
    }

    withAccount(account) {
        this.account = account;
        return this;
    }

    withNote(note) {
        this.note = note;
        return this;
    }

    withMarketingConsent(marketingConsent) {
        this.marketingConsent = marketingConsent;
        return this;
    }

    withMarketingConsentUpdated(marketingConsentUpdated) {
        this.marketingConsentUpdated = marketingConsentUpdated;
        return this;
    }

    withDateCreated(dateCreated) {
        this.dateCreated = dateCreated;
        return this;
    }

    withDateUpdated(dateUpdated) {
        this.dateUpdated = dateUpdated;
        return this;
    }

    withCustomerFullName(customerFullName) {
        this.customerFullName = customerFullName;
        return this;
    }

    withAnonimous(anonimous) {
        this.anonimous = anonimous;
        return this;
    }

    build() {
        return new CustomerResponseData(this);
    }
}

class CustomerResponseData extends BaseDto {
    constructor(builder) {
        super();
        this.uuid = builder.uuid;
        this.externalId = builder.externalId;
        this.firstName = builder.firstName;
        this.lastName = builder.lastName;
        this.platformsInfo = builder.platformsInfo;
        this.email = builder.email;
        this.phone = builder.phone;
        this.account = builder.account;
        this.note = builder.note;
        this.marketingConsent = builder.marketingConsent;
        this.marketingConsentUpdated = builder.marketingConsentUpdated;
        this.dateCreated = builder.dateCreated;
        this.dateUpdated = builder.dateUpdated;
        this.customerFullName = builder.customerFullName;
        this.anonimous = builder.anonimous;
    }

    static get Builder() {
        return CustomerResponseDataBuilder;
    }

    static fromObject(obj) {
        return new CustomerResponseDataBuilder()
            .withUuid(obj.uuid)
            .withExternalId(obj.externalId)
            .withFirstName(obj.firstName)
            .withLastName(obj.lastName)
            .withEmail(obj.email)
            .withPlatformsInfo(obj.platformsInfo)
            .withPhone(obj.phone)
            .withAccount(obj.account)
            .withNote(obj.note)
            .withMarketingConsent(obj.marketingConsent)
            .withMarketingConsentUpdated(obj.marketingConsentUpdated)
            .withDateCreated(obj.dateCreated)
            .withDateUpdated(obj.dateUpdated)
            .withCustomerFullName(obj.customerFullName)
            .withAnonimous(obj.anonimous)
            .build();
    }
}

export default CustomerResponseData;

import axios from 'axios';
import store from '@/store';

const baseURL = process.env.VUE_APP_API_URL;
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const metaService = {
    getMetaAccessCode(code) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/communication-channels/meta/access-token`,
            {
                code,
            },
        );
    },

    getInstagramAccessCode(code) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/communication-channels/meta/access-token/instagram`,
            {
                code,
            },
        );
    },

    getPageData(accessToken) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/communication-channels/meta/facebook-page-access-token`,
            {
                code: accessToken,
            },
        );
    },

    getInstagramData(accessToken) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/communication-channels/meta/instagram-access-token`,
            {
                code: accessToken,
            },
        );
    },

    subscribePageToMessengerWebhooks(accessToken, pageId) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/communication-channels/meta/subscribe-page-to-messenger-webhooks`,
            {
                access_token: accessToken,
                page_id: pageId,
            },
        );
    },

    subscribeInstagramToWebhooks(accessToken, accountId) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/communication-channels/meta/subscribe-instagram-to-webhooks`,
            {
                access_token: accessToken,
                accout_id: accountId,
            },
        );
    },

    registerWhatsappAccount(accessToken, phoneNumberId) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/communication-channels/meta/register-whatsapp-account`,
            {
                accessToken,
                phoneNumberId,
            },
        );
    },

    subscribeAppToWhatsappWebhooks(accessToken, wabaId) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/communication-channels/meta/subscribe-app-to-whatsapp-webhooks`,
            {
                accessToken,
                wabaId,
            },
        );
    },
};

export default metaService;

import BaseDto from '../../_common/BaseDto';

class ConversationWindowDtoBuilder {
    withUuid(uuid) {
        this.uuid = uuid;
        return this;
    }

    withIsClosed(isClosed) {
        this.is_closed = isClosed;
        return this;
    }

    withAccount(account) {
        this.account = account;
        return this;
    }

    withInvoiced(invoiced) {
        this.invoiced = invoiced;
        return this;
    }

    withConversation(conversation) {
        this.conversation = conversation;
        return this;
    }

    withSentiment(sentiment) {
        this.sentiment = sentiment;
        return this;
    }

    withHumanParticipation(humanParticipation) {
        this.human_participation = humanParticipation;
        return this;
    }

    withSummary(summary) {
        this.summary = summary;
        return this;
    }

    withTopic(topic) {
        this.topic = topic;
        return this;
    }

    withChannelType(channelType) {
        this.channel_type = channelType;
        return this;
    }

    withOwner(owner) {
        this.owner = owner;
        return this;
    }

    withTags(tags) {
        this.tags = tags;
        return this;
    }

    withDateCreated(dateCreated) {
        this.date_created = dateCreated;
        return this;
    }

    withDateClosed(dateClosed) {
        this.date_closed = dateClosed;
        return this;
    }

    build() {
        return new ConversationWindowDto(this);
    }
}

class ConversationWindowDto extends BaseDto {
    constructor(builder) {
        super();
        this.uuid = builder.uuid;
        this.is_closed = builder.is_closed;
        this.account = builder.account;
        this.invoiced = builder.invoiced;
        this.conversation = builder.conversation;
        this.sentiment = builder.sentiment;
        this.human_participation = builder.human_participation;
        this.summary = builder.summary;
        this.topic = builder.topic;
        this.channel_type = builder.channel_type;
        this.owner = builder.owner;
        this.tags = builder.tags;
        this.date_created = builder.date_created;
        this.date_closed = builder.date_closed;
    }

    static get Builder() {
        return ConversationWindowDtoBuilder;
    }
}

export default ConversationWindowDto;

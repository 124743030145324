import axios from 'axios';
import store from '@/store';
import CustomerResponseData from './Dto/CustomerResponseData';

const baseURL = `${process.env.VUE_APP_API_URL}`;

const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

/**
 * Repository for Customer API operations.
 * @namespace
 */
const customerApiRepository = {
    /**
     * Fetch customer data by UUID.
     * @param {string} customerId - The UUID of the customer to fetch.
     * @returns {Promise<CustomerResponseData>} A promise that resolves to a CustomerResponseData object.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    getCustomer: async (customerId) => {
        const url = `customers/${customerId}`;
        const response = await apiRequest('GET', url);

        const customerData = response.data;

        console.log({ customerData });

        return new CustomerResponseData.Builder()
            .withUuid(customerData.uuid)
            .withExternalId(customerData.external_id)
            .withFirstName(customerData.first_name)
            .withLastName(customerData.last_name)
            .withEmail(customerData.email)
            .withPhone(customerData.phone)
            .withPlatformsInfo(customerData.platformsInfo)
            .withAccount(customerData.account)
            .withNote(customerData.note)
            .withMarketingConsent(customerData.marketing_consent)
            .withMarketingConsentUpdated(customerData.marketing_consent_updated)
            .withDateCreated(customerData.date_created)
            .withDateUpdated(customerData.date_updated)
            .withCustomerFullName(customerData.customer_full_name)
            .withAnonimous(customerData.anonimous)
            .build();
    },
};

export default customerApiRepository;

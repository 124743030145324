<template>
    <div>
        <md-button :disabled="isLoading" @click="launchWhatsAppSignup" class="md-primary">
            <md-progress-spinner
                v-if="isLoading"
                :md-diameter="12"
                :md-stroke="2"
                class="md-white"
                md-mode="indeterminate"
            ></md-progress-spinner>
            <span v-else>Connect</span>
        </md-button>
    </div>
</template>

<script>
import communicationChannelsService from '@/services/account/communicationChannelsService';
import metaService from '@/services/meta/metaService';
import Swal from 'sweetalert2';

export default {
    name: 'ChannelsWhatsappLoginButton',

    emits: ['account-connected'],

    data() {
        return {
            code: null,
            phone_number_id: null,
            waba_id: null,
            isLoading: false,
        };
    },

    watch: {
        code(newVal) {
            this.handleCodeChange(newVal);
        },
    },

    computed: {
        processIsCompleted() {
            return this.code && this.phone_number_id && this.waba_id;
        },
    },

    methods: {
        loadFacebookSDK() {
            window.fbAsyncInit = function () {
                FB.init({
                    appId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
                    cookie: true,
                    xfbml: true,
                    version: 'v19.0',
                });
            };

            // Load the JavaScript SDK asynchronously
            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        },

        launchWhatsAppSignup() {
            FB.login(
                (response) => {
                    if (response.authResponse) {
                        this.code = response.authResponse.code;
                    } else {
                        this.isLoading = false;
                        console.log('User cancelled login or did not fully authorize.');
                    }
                },
                {
                    config_id: process.env.VUE_APP_WHATSAPP_CONFIGURATION_ID,
                    response_type: 'code',
                    override_default_response_type: true,
                    extras: {
                        feature: 'whatsapp_embedded_signup',
                        sessionInfoVersion: 2,
                    },
                },
            );

            window.addEventListener('message', this.sessionInfoListener);
        },

        sessionInfoListener(event) {
            if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
                this.isLoading = false;
                return;
            }

            try {
                const data = JSON.parse(event.data);
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    // if user finishes the Embedded Signup flow
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        this.phone_number_id = phone_number_id;
                        this.waba_id = waba_id;
                        window.removeEventListener('message', this.sessionInfoListener);
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const { current_step } = data.data;
                        console.log('Process cancelled, last step: ', current_step);
                    }
                }
            } catch {
                // Don’t parse info that’s not a JSON
                console.log('Non JSON Response', event.data);
            } finally {
                this.isLoading = false;
            }
        },

        async handleCodeChange(code) {
            if (!this.processIsCompleted) {
                return;
            }

            try {
                const accessToken = await this.obtainFacebookToken(code);
                await this.registerWhatsappAccount(accessToken);
                await this.subscribeWhatsappAccountToWebhooks(accessToken);

                await this.createWhatsAppChannel(accessToken, this.phone_number_id, this.waba_id);
            } catch (e) {
                console.error('Error creating communication channel: ', e);
            }
        },

        async subscribeWhatsappAccountToWebhooks(accessToken) {
            try {
                await metaService.subscribeAppToWhatsappWebhooks(accessToken, this.waba_id);
            } catch (e) {
                console.error('Error subscribing account to webhooks');
            }
        },

        async registerWhatsappAccount(accessToken) {
            try {
                const pin = metaService.registerWhatsappAccount(accessToken, this.phone_number_id);

                this.showAlert(
                    '2FA Pin',
                    `Your new PIN for two-step verification is: ${pin}, keep it in a safe place.`,
                );
            } catch (e) {
                console.error('Error registering account: ', e);
            }
        },

        async obtainFacebookToken(code) {
            try {
                const response = await metaService.obtainFacebookToken(code);
                return response.data;
            } catch (e) {
                console.error('Error obtainFacebookToken: ', e);
            }
        },

        async createWhatsAppChannel(access_token, phone_number_id, waba_id) {
            try {
                const reqData = {
                    access_token,
                    phone_number_id: phone_number_id,
                    business_account_id: waba_id,
                    channel_type: 'WHATSAPP',
                };
                await communicationChannelsService.createCommunicationChannel(reqData);
                this.showSuccessToast('Channel created successfully.');
                this.$emit('account-connected');
            } catch (e) {
                console.error('Error createWhatsAppChannel: ', e);
                this.showErrorToast('Error creating WhatsApp channel');
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        showAlert(title, msg) {
            Swal.fire({
                title: title,
                text: msg,
                confirmButtonColor: '#4A25E1',
                confirmButtonText: 'Got it!',
                buttonsStyling: true,
            });
        },
    },
    mounted() {
        this.loadFacebookSDK();
    },
    beforeUnmount() {
        window.removeEventListener('message', this.sessionInfoListener);
        window.removeEventListener('beforeunload', this.handleWindowClose);
    },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
